.header {
  display: flex;
  align-items: center;
  position: absolute;
  width: 90%;
  top: 0px;
  z-index: 1;
  padding: 0px 70px;
}
.searchArea {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  top: 10px;
  border-radius: 4px;
  padding: 12px;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  gap: 12px;
  font-size: 14px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 200ms;
}
.menuItem:hover {
  background-color: #e9e9e9;
}
