@import url("./styles/toast.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.circle-annotation {
  display: flex;
  justify-content: center;
  padding: 4px;
  height: 45px;
  border-bottom: 0px;
  border-top: 0px;
  font-size: 14px;
  text-align: center;
}
.circle-annotation::before {
  content: "";
  min-width: 25px;
  position: absolute;
  top: -25px;
  height: 25px;
  border-radius: 50%;
  background-color: orange;
}
