.search_input {
  background-color: rgba(255, 255, 255, 0.8); /* transparent */
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  border: 1px #cccccc solid;
  outline: none;
}

.location_container {
  display: flex;
  align-items: center;
  padding: 0.8rem 0rem;
  width: 100%;
  color: pointer;
  gap: 1rem;
  font-size: 12px;
}
.location_container:hover {
  background-color: rgba(255, 165, 0, 0.6);
}
.isActive {
  background-color: rgba(255, 165, 0, 0.2);
}
.location_search_container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 38.5rem;
  min-width: 18.75rem;
}
