.landmark {
  width: 250px;
  padding: 7px 0 0 0;
  background: rgba(247, 247, 247, 0.75);
  border-radius: 5px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
  font-family: Helvetica, Arial, sans-serif;
  -webkit-transform-origin: 0 10px;
  transform-origin: 0 10px;
}

.landmark h1 {
  margin-top: 0;
  padding: 5px 15px;
  background: #2aaef5;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 300;
}

.landmark section {
  padding: 0 15px 5px;
  font-size: 14px;
}

.landmark section p {
  margin: 5px 0;
}

.landmark:after {
  content: "";
  position: absolute;
  top: 7px;
  left: -13px;
  width: 0;
  height: 0;
  margin-bottom: -13px;
  border-right: 13px solid #f28c28;
  border-top: 13px solid rgba(0, 0, 0, 0);
  border-bottom: 13px solid rgba(0, 0, 0, 0);
}

.project_title {
  background-color: #f28c28 !important;
}
.project_link {
  display: inline-block;
  margin-top: 6px;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  color: #f28c28 !important;
  &:hover {
    color: #ffffff !important;
    background-color: #f28c28 !important;
    text-decoration: none;
  }
}
